<script setup lang="ts">
import { signOut } from "aws-amplify/auth";

const router = useRouter();
const route = useRoute();

const menus = [
  {
    title: "ダッシュボード",
    path: "/",
  },
  {
    title: "リソース管理",
    path: "/resource",
  },
  {
    title: "メタバース空間管理",
    path: "/room",
  },
];

const isActive = (path: string) => {
  if (path === "/") return route.path === path;

  return route.path.includes(path);
};

const link = (path: string) => {
  router.push(path);
};

const logout = async () => {
  try {
    await signOut();
    router.push("/login");
  } catch (e) {
    console.error("error siging out", e);
    alert(e);
  }
};
</script>

<template>
  <ClientOnly>
    <v-navigation-drawer permanent color="#2c17ae">
      <v-list nav mandatory active-class="active" class="pr-0">
        <v-list-item
          v-for="item in menus"
          :key="item.title"
          :active="isActive(item.path)"
          @click="link(item.path)"
          >{{ item.title }}</v-list-item
        >
      </v-list>

      <template #append>
        <div class="pa-4">
          <ProfileCard />
          <v-btn block @click="logout"> ログアウト </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </ClientOnly>
</template>

<style lang="scss">
.v-navigation-drawer--left {
  border: none !important;
}

.active {
  color: black !important;
  background-color: #fff !important;
  border-radius: 5px 0 0 5px;

  &.v-list-item--rounded {
    border-radius: 4px 0 0 4px;
  }

  .v-list-item__overlay {
    background-color: white !important;
  }
}
</style>
