<script setup lang="ts">
import { getCurrentUser, type AuthUser } from "aws-amplify/auth";

const user = ref<AuthUser>();

onMounted(async () => {
  user.value = await getCurrentUser();
});
</script>

<template>
  <v-card flat color="transparent" class="text-center">
    <v-card-title>{{ user?.username }}</v-card-title>
  </v-card>
</template>
